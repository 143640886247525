import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HealthStatus from './HealthStatus';
import GameMasterOracle from './pages/GameMasterOracle';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/healthstatus" element={<HealthStatus />} />
                <Route path="/" element={<GameMasterOracle />} />
            </Routes>
        </Router>
    );
}

export default App;
